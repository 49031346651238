.profile {
    position: fixed;
    top: 0;
}

.display-label {
    margin: 0px 8px;
}
.display-value {
    font-weight: 600;
}

.war {
    position: fixed;
    width: 100%;
    height: 100%;
}