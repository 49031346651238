.war {
    display: flex;
    justify-content: space-between;
}

.troop-selector {
    width: 50vw;
    height: 70vh;
    overflow: auto;

    .selected-troops {
        height: calc(100% - 50px);
    }

    .troop-cards {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 100%;

        .troop-card {
            height: 80px;
            width: 60px;
            border: 1px solid black;
            margin: 4px;
            border-radius: 5px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            background-size: contain;

            .troops {
                color: white;
                text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
            }

            .troop-id {
                display: none;
            }
        }

        .selected {
            border: 3px solid green;
            margin: 2px;
        }
    }
}