.map {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .map-tiles {
        background-image: url('../public/background.jpg');
        background-size: 100% 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 70%;
        height: 70%;


        .map-tile-row {
            display: flex;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;

            .map-tile {
                width: 100%;
                height: 100%;
                border: 1px solid black;
                position: relative;
                box-sizing: border-box;
            }

            .target::before {
                border: 3px solid limegreen;
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                margin: -3px;
            }
        }
    }

    @keyframes owned-glow {
        0% {border-color: limegreen;}
        50% {border-color: green;}
        100% {border-color: limegreen;}
      }

      .owned {
        animation-name: owned-glow;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        border-color: limegreen;
        border-style: solid;
        border-width: 2px;
      }

    .entry-camps {
        position: absolute;
        width: 100%;
        height: 100%;

        .camp-marker {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            background: green;
            margin: 10px;
            z-index: 2;
        }

        .camp {
            display: flex;
            width: 100%;
            position: absolute;
            height: 100%;

            &.north-camp {
                justify-content: center;
                align-items: flex-start;
            }

            &.south-camp {
                justify-content: center;
                align-items: flex-end;
            }

            &.west-camp {
                justify-content: flex-start;
                align-items: center;
            }

            &.east-camp {
                justify-content: flex-end;
                align-items: center;
            }
        }
    }

    .attackOptions{
        position: absolute;
        top:0;
        left:0;
        
        .attack-vector {
            position: absolute;
            transform-origin: center left;
            opacity: 60%;
        }

        .active {
            stroke: green;
            opacity: 100%;
        }
    }
}

.modal-background{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: #00000040;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }    
}

.selector-controls {
    width: 10%;
}

.owner-1 {
    background-color: #00ff0077;
}